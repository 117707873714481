@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }

  body {
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
  }

  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68d391 !important;
    background: white;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68d391;
  }
  [type='checkbox'],
  [type='radio'] {
    box-shadow: none !important;
  }

  .toggle-switch [type='checkbox']:checked:hover,
  .toggle-switch [type='checkbox']:checked:focus,
  .toggle-switch [type='radio']:checked:hover,
  .toggle-switch [type='radio']:checked:focus {
    background-color: white;
    outline: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
}

@keyframes chatAppear {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  opacity: 0;
  animation-name: fadeInDown;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-scaleIn {
  animation: scaleIn 500ms ease;
  animation-fill-mode: forwards;
}

*.animate-article-scaleIn > *:not(*.animate-article-scaleIn) {
  opacity: 0;
  animation: scaleIn 1000ms ease forwards;
  will-change: transform, opacity;
}

*.animate-article-scaleIn > *:nth-child(1) {
  animation-delay: 0ms;
}
*.animate-article-scaleIn > *:nth-child(2) {
  animation-delay: 100ms;
}
*.animate-article-scaleIn > *:nth-child(3) {
  animation-delay: 200ms;
}
*.animate-article-scaleIn > *:nth-child(4) {
  animation-delay: 300ms;
}
*.animate-article-scaleIn > *:nth-child(5) {
  animation-delay: 400ms;
}
*.animate-article-scaleIn > *:nth-child(n + 6) {
  animation-delay: 500ms;
}

.line-through {
  text-decoration: none;
  position: relative;
}
.line-through:after {
  content: '';
  position: absolute;
  display: block;
  width: 115%;
  height: 2px;
  background-color: red;
  top: 50%;
  left: -3px;
  opacity: 0.6;
}

/* .ReactModal__Body--open {
  overflow: hidden;
} */

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #4285f4;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #4285f4;
  border-left-color: #4285f4;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.google-button {
  border-color: #4285f4;
  color: #4285f4;
}

.hide-outer-border {
  position: relative;
}

.hide-outer-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px white solid;
  pointer-events: none;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(-2.5px);
  }
  50% {
    transform: translateY(2.5px);
  }
}

@keyframes show-hide {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@layer utilities {
  .animate-float {
    animation: float infinite ease-in-out 2s;
    animation-direction: alternate;
  }
  .animate-show-hide {
    animation: show-hide infinite ease-in-out 4s;
  }

  .animate-delay-100 {
    animation-delay: 100ms;
  }
  .animate-delay-200 {
    animation-delay: 200ms;
  }
  .animate-delay-300 {
    animation-delay: 300ms;
  }
  .animate-delay-400 {
    animation-delay: 400ms;
  }
  .animate-delay-500 {
    animation-delay: 500ms;
  }
  .animate-delay-750 {
    animation-delay: 750ms;
  }
  .animate-delay-1000 {
    animation-delay: 1000ms;
  }

  .card-transform-left {
    left: 50%;
    transform: translateX(-50%) rotateX(1deg) rotateY(2deg);
  }

  .card-transform-left-2 {
    left: 0%;
    transform: rotateX(1deg) rotateY(2deg);
  }

  .card-transform-right {
    left: 50%;
    transform: translateX(-50%) rotate3d(-1, 1, 0, -5deg);
  }

  .card-transform-right-2 {
    right: 0%;
    transform: rotate3d(-1, 1, 0, -5deg);
  }

  .scale-99 {
    --tw-scale-x: 0.99;
    --tw-scale-y: 0.99;
  }
  .scale-80 {
    --tw-scale-x: 0.8;
    --tw-scale-y: 0.8;
  }

  @layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.recharts-surface {
  overflow: visible !important;
}

.draw-highlight {
  position: relative;
}
.draw-highlight:after {
  content: '';
  background: url(/markup.png) top/contain no-repeat;
  width: 100%;
  display: block;
  height: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(90%);
  z-index: -1;
  /* make it white */
  filter: brightness(0) invert(1);
}

.ReactTags__tagInput {
  width: 100%;
}
.ReactTags__tagInputField {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: inherit;
  padding: 0;
  width: 100%;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  padding: 0.75em;
}

.ReactTags__tags {
  width: 100%;
}

.tag-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.1em 0.5em;
  border-radius: 0.5em;
  background-color: #e2e8f0;
}

.tag-wrapper button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ReactTags__suggestions {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em #e2e8f0;
  padding: 0.5em;
  margin-top: 1em;

  overflow-y: auto;
  max-height: 15em;
}

.ReactTags__suggestions li {
  list-style: none;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 0.5em;
  text-transform: capitalize;
}

.ReactTags__suggestions li:hover {
  background-color: #e2e8f0;
}

.highcharts-credits {
  display: none;
}

.pseudo-white-filler::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: -1;
  transform: translateX(100%);
}
.psuedo-white-filler::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: -1;
  transform: translateX(-100%);
}

.prose-remove-first-margin > h1:first-of-type,
.prose-remove-first-margin > h2:first-of-type,
.prose-remove-first-margin > h3:first-of-type,
.prose-remove-first-margin > h4:first-of-type,
.prose-remove-first-margin > h5:first-of-type,
.prose-remove-first-margin > h6:first-of-type,
.prose-remove-first-margin div > h1:first-of-type,
.prose-remove-first-margin div > h2:first-of-type,
.prose-remove-first-margin div > h3:first-of-type,
.prose-remove-first-margin div > h4:first-of-type,
.prose-remove-first-margin div > h5:first-of-type,
.prose-remove-first-margin div > h6:first-of-type {
  margin-top: 0 !important;
}

/* #embedded-checkout iframe {
  width: 100%;
  height: 100% !important;
  border: none;
} */
